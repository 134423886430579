<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal(false)"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Selecione o consultor') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>
        <b-overlay
          :show="processingAjax"
          rounded="sm"
        >
          <vue-perfect-scrollbar
            class="b-sidebar-scrollarea-form"
            :settings="configSettingsScroll"
          >
            <b-container
              class="pt-2 pb-0 pl-2 pr-2"
              fluid
            >
              <!-- Botoes de acoes -->
              <b-row>
                <b-col md="12 mb-1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    @click="sendChange"
                  >
                    {{ $t('Alterar') }}
                  </b-button>
                </b-col>
              </b-row>

              <b-row md="mt-1">
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Alterar o consultor angariador')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-model="agentSel"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="searchAgent"
                        append-to-body
                        :calculate-position="withPopper"
                        :loading="isLoadingAgent"
                        :filterable="false"
                        label="nome"
                        item-text="nome"
                        item-value="id"
                        @search="searchAgentsByUserAuth"
                      >
                        <div
                          v-if="searchAgent.length > 0"
                          slot="no-options"
                        >
                          {{ $t('Sem resultados') }}
                        </div>
                        <template
                          v-if="searchAgent.length === 0"
                          slot="no-options"
                        >
                          {{ $t('Digite o nome') }}
                        </template>
                        <template
                          slot="option"
                          slot-scope="option"
                        >
                          <div class="d-flex align-items-center">
                            <b-avatar
                              :src="option.imagem"
                              class="mr-50"
                            />
                            <div class="detail">
                              <b-card-text class="mb-0">
                                {{ option.nome }}
                              </b-card-text>
                              <b-card-text
                                v-if="option.email"
                                class="mb-0 text-muted"
                              >
                                <small>{{ option.email }}</small>
                              </b-card-text>
                              <b-card-text
                                v-if="option.telefone"
                                class="mb-0 text-muted"
                              >
                                <small>{{ option.telefone }}</small>
                              </b-card-text>
                              <b-card-text
                                v-if="option.nameHUB"
                                class="mb-0 text-muted"
                              >
                                <small>{{ option.nameHUB }}</small>
                              </b-card-text>
                            </div>
                          </div>
                        </template>
                        <template
                          slot="selected-option"
                          slot-scope="option"
                        >
                          {{ option.nome }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row v-if="filterChangeAgent.MostraCheck === true">
                <b-col>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="checkTransf"
                      value="1"
                    >
                      {{ $t('Solicitar permissão a ') }} {{ filterChangeAgent.Consultor }} {{ $t(' para transferir as notas e documentos privados') }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <div v-if="Object.keys(filterChangeAgent.propostasConsultor).length > 0 || Object.keys(filterChangeAgent.processosVendaConsultor).length > 0">

                <b-row v-if="filterChangeAgent.estadoAngariacao.sw018v02 !== ''">
                  <b-col>
                    <b-form-group
                      :label="$t('Estado da angariação')"
                    >
                      <p class="text-primary mb-0">
                        {{ filterChangeAgent.estadoAngariacao.sw018v02 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="Object.keys(filterChangeAgent.propostasConsultor).length > 0">
                  <b-col>
                    <b-form-group
                      :label="$t('Propostas em curso')"
                    >
                      <p class="text-primary mb-0">
                        <span
                          v-for="(row, index) in filterChangeAgent.propostasConsultor"
                          :key="`proposal-${index}`"
                        >
                          {{ row.sw130s15 }};
                        </span>
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="Object.keys(filterChangeAgent.processosVendaConsultor).length > 0">
                  <b-col>
                    <b-form-group
                      :label="$t('Processos de venda em curso')"
                    >
                      <p class="text-primary mb-0">
                        <span
                          v-for="(row, index) in filterChangeAgent.processosVendaConsultor"
                          :key="`proposal-${index}`"
                        >
                          {{ row.sw152s17 }};
                        </span>
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="Number(filterChangeAgent.totalVisitasConsultor) > 0 || Number(filterChangeAgent.totalPropostasConsultor) > 0">
                  <b-col>
                    <b-form-group
                      :label="$t('Total de registos que serão movidos para histórico')"
                    >
                      <p
                        v-if="Number(filterChangeAgent.totalVisitasConsultor) > 0"
                        class="text-primary mb-0"
                      >
                        {{ filterChangeAgent.totalVisitasConsultor }} {{ $t('visitas') }}
                      </p>

                      <p
                        v-if="Number(filterChangeAgent.totalPropostasConsultor) > 0"
                        class="text-primary mb-0"
                      >
                        {{ filterChangeAgent.totalPropostasConsultor }} {{ $t('propostas') }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <b-alert
                      show
                      variant="info"
                    >
                      <div class="alert-body">
                        <feather-icon
                          class="mr-25"
                          icon="InfoIcon"
                        />
                        <span class="ml-25">{{ $t('Ao alterar o consultor angariador, as propostas e processos de venda acima indicados vão passar para a responsabilidade do novo consultor') }}</span>
                      </div>
                    </b-alert>
                  </b-col>
                </b-row>
              </div>

            </b-container>

          </vue-perfect-scrollbar>
          <div
            class="d-flex p-1 justify-content-center"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click.stop.prevent="hide"
            >
              {{ $t('Fechar') }}
            </b-button>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BContainer, BOverlay, VBTooltip, BRow, BCol, BButton, BFormGroup, BFormCheckbox, BAlert, BAvatar, BCardText,
} from 'bootstrap-vue'
import '@/libs/sweet-alerts'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BSidebar,
    BContainer,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BAlert,
    BAvatar,
    BCardText,
    VuePerfectScrollbar,
    ValidationProvider,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    filterChangeAgent: {
      type: Object,
      required: true,
    },
    submitChangeAgent: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      agentSel: null,
      checkTransf: null,
      isLoadingAgent: false,
      searchAgent: this.filterChangeAgent.UsersMkt,
    }
  },
  async created() {
    this.processingAjax = false
  },
  methods: {
    closeModal(val) {
      this.$parent.showFormChangeAgent = val
    },
    resetAgent(all) {
      this.agent = null

      if (all === true) {
        this.agentSel = null
      }
    },
    async searchAgentsByUserAuth(text) {
      this.resetAgent()
      if (text !== '') {
        this.isLoadingAgent = true

        const aAgents = []
        await Array.from(this.filterChangeAgent.UsersMkt).forEach(item => {
          const name = item.nome.toLowerCase()
          if (name.includes(text.toLowerCase())) {
            aAgents.push(item)
          }
        })
        this.searchAgent = aAgents
        this.isLoadingAgent = false
      } else {
        this.searchAgent = this.filterChangeAgent.UsersMkt
      }
    },
    async sendChange(evt) {
      evt.preventDefault()

      if (this.agentSel !== null && this.agentSel !== '' && typeof this.agentSel.id !== 'undefined') {
        this.submitChangeAgent(this.agentSel, this.checkTransf)
      } else {
        this.showMsgErrorRequest(new Error(this.$t('Tem de escolher o novo consultor')))
      }
    },
    resetData() {
      this.agentSel = null
      this.checkTransf = null
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>

.force-padding-left-05{
  padding-left: 0.5rem !important;
}

.force-padding-0{
  padding:0 !important;
}

</style>
